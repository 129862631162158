// 印度尼西亚
export default {
    // 公共
    "请求超时": "Meminta batas waktu",    "请求超时服务器链接失败": "Tautan server timeout permintaan gagal",
    // components Geet
    "行为验证™ 组件加载中": "Pemuatan Komponen Perilaku Verifikasi ™",    "点击进行验证": "Klik untuk memverifikasi",    "验证成功": "Verifikasi yang berhasil",
    // sideBar
    "您还未登录": "Anda belum masuk",    "登录": "Masuk",    "注册": "daftar",    "后查看": "Lihat",    "钱包地址": "Alamat dompet",    "未实名": "Nama yang tidak nyata",    "已实名": "Nama asli",    "审核中": "Tinjauan",    "复制成功": "Replikasi",    "实名认证": "Otentikasi asli -Name",    "扫一扫": "Menyapu",    "交易订单": "Pesanan perdagangan",    "收款信息": "Informasi tanda terima",    "资金明细": "Detail Modal",    "个人报表": "Laporan Pribadi",    "代理佣金": "Komisi Agen",    "关于我们": "tentang kami",    "帮助中心": "Pusat Bantuan",    "在线客服": "Layanan Pelanggan Online",    "修改登录密码": "Ubah Kata Sandi Login",    "修改支付密码": "Ubah Kata Sandi Pembayaran",    "退出登录": "Keluar Masuk",    "退出成功": "KELUAR",
    // sellCoin
    "输入出售数量": "Masukkan jumlah penjualan",    "可售余额": "Saldo penjualan",    "单次出售最小数量": "Jumlah penjualan minimum",    "收款方式": "Metode tanda terima",    "出售方式": "Cara penjualan",    "不拆分": "Tidak terpecah",    "拆分": "Membelah",    "出售": "menjual",    "请输入支付密码": "Harap masukkan kata sandi pembayaran",    "支付密码": "Kata sandi pembayaran",    "提示": "petunjuk",    "请先添加收付款方式": "Tambahkan metode pembayaran terlebih dahulu",    "请输入出售数量": "Harap masukkan jumlah penjualan",    "出售数量不能小于单次最小数量": "Jumlah penjualan tidak bisa kurang dari jumlah minimum",    "请选择收款方式": "Pilih metode pembayaran",    "上架成功": "Berhasil diletakkan di rak",    "操作失败，请检查网络重试或者联系客服": "Jika operasinya gagal, silakan periksa Internet untuk coba lagi atau hubungi Layanan Pelanggan",    "RMB": "RMB",    // Usdt
    "当前余额": "Saldo saat ini",    "币": "mata uang",    "类型": "jenis",    "请选择USDT类型": "Pilih Jenis USDT",    "链路": "link",    "请选择USDT链路": "Pilih tautan USDT",    "数量": "kuantitas",    "请输入要充值的USDT数量": "Harap masukkan jumlah USDT yang akan diisi ulang",    "请输入要提现的USDT数量": "Harap masukkan jumlah USDT yang akan dipermatakan",    "地址": "alamat",    "请选择USDT地址": "Pilih Alamat USDT",    "请输入提现的USDT-TRC20地址": "Harap masukkan alamat penarikan USDT-TRC20",    "密码": "kata sandi",    "注意": "Melihat",    "转账时请核对收款地址是否一致，避免造成无法追回损失!": "Silakan periksa apakah alamat penerimaan konsisten selama transfer untuk menghindari menyebabkan tidak dapat memulihkan kerugian!",    "填写地址时请再次核实是否正确，避免造成无法追回损失！": "Saat mengisi alamat, harap verifikasi apakah benar untuk menghindari menyebabkan kegagalan untuk pulih!",    "支付凭证": "Voucher pembayaran",    "上传支付凭证": "Unggah voucher pembayaran",    "提交充值": "Kirimkan isi ulang",    "提交提现": "Kirim penarikan",    "暂无数据": "Tidak ada data",    "完成": "Menyelesaikan",    "上传中": "Mengunggah",    "USDT地址已复制": "Alamat USDT telah disalin",    "操作失败": "Gagal beroperasi",    "当前账户余额不足": "Saldo akun saat ini tidak mencukupi",    "请输入USDT数量": "Harap masukkan jumlah USDT",    "充值的USDT数量小于最低配置限制": "Jumlah USDT untuk mengisi ulang kurang dari batas konfigurasi minimum",    "提现的USDT数量小于最低配置限度": "Jumlah USDT penarikan kurang dari batas konfigurasi minimum",    "USDT地址只能包含英文和数字": "Alamat USDT hanya dapat berisi bahasa Inggris dan angka",    "支付密码不能为空": "Kata sandi pembayaran tidak bisa kosong",    "报价": "Mengutip",
    // Deposit
    "充值": "Top up",
    // login
    "手机号/账号": "Nomor Ponsel/Nomor Akun",    "请输入手机号或账号": "Harap masukkan nomor ponsel atau nomor akun",    "下一步": "Langkah selanjutnya",    "创建一个账户": "Buat akun {0}",    "请输入您的密码": "Harap masukkan kata sandi Anda",    "记住密码": "Ingat kata sandinya",    "联系客服": "Hubungi Layanan Pelanggan",    "输入验证码": "Masukkan kode konfirmasi",    "确定": "Tentu",    "请输入密码": "Harap masukkan kata sandi",    "请输入验证码": "Harap masukkan kode verifikasi",    "请点击进行验证": "Silakan klik untuk memverifikasi",    "登录成功": "Login yang berhasil",    "账户": "Akun",
    // Register
    "创建账户": "Buat akun",    "账号": "akun",    "手机号": "Nomor telepon",    "已有账号，去登录": "Akun yang ada, masuk",    "设置登录与支付密码": "Atur kata sandi login dan pembayaran",    "验证码已发送": "Kode verifikasi telah dikirim",    "请输入短信验证码": "Harap masukkan kode verifikasi SMS",    "重新获取": "Re -obtain",    "请输入登录密码": "Harap masukkan kata sandi login",    "请输入数字与英文组合至少6位": "Harap masukkan kombinasi nomor dan bahasa Inggris setidaknya 6 digit",    "请确认登录密码": "Harap konfirmasi kata sandi login",    "请保持登录密码一致": "Harap jaga agar kata sandi login tetap konsisten",    "请输入手机号码": "Harap masukkan nomor ponsel",    "发送中": "Mengirim",    "发送成功": "Kirim dengan sukses",    "请输入用户名": "Harap masukkan nama pengguna",    "请再次确认密码": "Harap konfirmasi kata sandi lagi",    "两次密码不一致": "Dua kata sandi tidak konsisten",    "请设置支付密码": "Harap atur kata sandi pembayaran",    "注册成功": "Mendaftar dengan sukses",    "请输入账号": "Harap masukkan nomor akun",    "请设置6位纯数字支付密码": "Harap atur 6 -Bit Kata Sandi Pembayaran Digital Murni",
    //AgencyCommission
    "全部": "semua",    "买单佣金": "Membayar komisi",    "卖单佣金": "Penjualan",    "至": "ke",    "请输入搜索的订单编号": "Harap masukkan nomor pesanan pencarian",    "搜索": "mencari",    "已派发佣金": "Komisi",    "订单号": "Nomor pesanan",    "返佣账号": "Akun rabat",    "交易金额": "Jumlah transaksi",    "买卖类型": "Jual beli",    "返佣比例": "Rasio komisi",    "返佣金额": "Jumlah komisi",    "返佣方式": "Cara pengembalian",    "返佣时间": "Waktu rabat",    "返佣派发": "Potongan harga",    "没有更多了": "Tidak lebih",    "买单": "Membayar",    "卖单": "Jual pesanan",    "日结": "Simpul Hari",    "周结": "Mingguan",    "月结": "Simpul bulanan",    "已返佣": "Potongan harga",    "未返佣": "Paman",    "进行中": "sedang berlangsung",    "已完成": "Menyelesaikan",    "已取消": "Membatalkan",    "人工退回": "Secara manual",    "人工打币": "Mata uang buatan",    "出售中": "Penjualan",    "已冻结": "Beku",    "交易中": "Transaksi",    "待支付": "Untuk membayar",    "成功": "kesuksesan",    "失败": "gagal",
    // FundRecord
    "取消": "Membatalkan",    "人工加款": "Peningkatan buatan",    "人工扣款": "Pengurangan buatan",    "买币-成功": "Beli mata uang yang luar biasa",    "上架货币": "Mata uang",    "上架货币强制退回": "Mata uang untuk pengembalian wajib di rak",    "卖币成功": "Koin yang sukses",    "api充值": "API Recharge",    "api提现": "Penarikan API",    "佣金": "komisi",    "用户转账": "Transfer pengguna",    "用户收款": "Koleksi Pengguna",    "代理转账": "Transfer proxy",    "代理收款": "Proxy",    "USDT充值": "USDT Recharge",    "USDT提现": "Penarikan USDT",    "USDT提现退回": "Pengembalian penarikan USDT",
    // HelpCenter
    "买币": "Beli mata uang",    "卖币": "Menjual",    "提现": "Menarik",    "快捷买币": "Koin pembelian cepat",
    // ModifyLoginPassword
    "旧密码": "Kata sandi lama",    "请输入旧密码": "Harap masukkan kata sandi lama",    "新密码": "Kata sandi baru",    "请输入新密码": "Harap masukkan kata sandi baru",    "确认密码": "Konfirmasi Kata Sandi",    "确认": "mengonfirmasi",    "请再次输入新密码": "Harap masukkan kata sandi baru lagi",    "两次新密码不一致": "Dua kata sandi baru tidak konsisten",    "修改成功": "Memodifikasi",
    // ModifyPaymentPassword
    "旧支付密码": "Kata sandi pembayaran lama",    "新支付密码": "Kata Sandi Pembayaran Baru",    "确认支付密码": "Konfirmasikan Kata Sandi Pembayaran",
    // PersonalData
    "个人资料": "Informasi pribadi",    "设置头像": "Atur avatar",    "修改昵称": "Ubah nama panggilan",    "请输入新的昵称": "Harap masukkan nama panggilan baru",
    // PersonalReport
    "买单总额": "Jumlah total",    "卖单总额": "Total penjualan",    "API充值总额": "Total Isi Ulang API",    "API充值返点": "API Recharge Point Return",    "API提现总额": "API dengan jumlah total",    "交易纠纷订单总额": "Jumlah total pesanan sengketa perdagangan",    "转账总额": "Transfer total",    "收款总额": "Total tanda terima",
    // BuyerOrderDetails
    "订单详情": "Detail pesanan",    "发起订单": "Memulai pesanan",    "卖家确认": "Penjual mengkonfirmasi",    "凭证已上传": "Voucher telah diunggah",    "卖家已打币": "Penjual telah membuat koin",    "卖家已暂停": "Penjual telah ditangguhkan",    "订单编号": "Nomor pesanan",    "买家名字": "Nama Pembeli",    "买家昵称": "Nama panggilan pembeli",    "金额": "Jumlah",    "订单时间": "Waktu pesanan",    "付款方式": "Metode pembayaran",    "支付宝": "Alipay",    "微信": "Wechat wechat",    "银行卡": "kartu bank",    "开户行": "Pembukaan akun",    "银行卡号": "Nomor Kartu Bank",    "支付剩余时间": "Membayar sisa waktu",    "卖家名字": "Nama Penjual",    "卖家昵称": "Nama panggilan penjual",    "取消订单": "Batalkan pesanan",    "上传凭证": "Unggah voucher",    "买家上传支付凭证": "Pembeli mengunggah voucher pembayaran",    "已转账，等待卖方确认": "Transfer, menunggu penjual mengonfirmasi",    "查看凭证": "Lihat voucher",    "创建时间": "Waktu penciptaan",    "取消时间": "Batalkan waktu",    "订单已取消": "Pesanan telah dibatalkan",    "撤单备注": "Perkataan",    "冻结时间": "Waktu pembekuan",    "订单已冻结": "Pesanan telah dibekukan",    "卖家暂停打币": "Penjual jeda koin",    "卖家收款二维码": "Kode Penerimaan Penjual Kode QR",    "温馨提示": "Tips yang baik",    "买家付款凭证": "Voucher Pembayaran Pembeli",    "是否撤单": "Apakah akan mundur",    "撤单原因": "Alasan Pesanan",    "撤单原因可为空": "Alasan penarikan bisa kosong",    "撤单成功": "Penarikan yang berhasil",    "点击扫码付款": "Klik untuk Memindai Pembayaran Kode",
    // Order
    "订单": "Memesan",    "卖币订单":"Menjual pesanan",    "挂单": "Menggantung",    "API订单": "Pesanan API",    "加款": "Meningkatkan",    "扣款": "Deduksi",    "挂单编号": "Nomor pesanan gantung",    "下架": "Melepas rak",    "总出售": "Total penjualan",    "失败原因": "Menyebabkan",    "系统冻结": "Sistem beku",    "处理中": "Pengolahan",    "拒绝": "menolak",    "确认将该笔订单进行下架吗": "Apakah Anda mengkonfirmasi bahwa pesanan telah dihapus?",    "下架成功": "Berhasil dihapus",
    // SellerOrderDetails
    "卖单详情": "Jual detail pesanan",    "购买数量": "Kuantitas Beli",    "撤销": "Menarik kembali",    "等待买家付款": "Menunggu pembeli membayar",    "暂停打币": "Berhenti sebentar",    "确认打币": "Konfirmasi koin",    "买家已付款": "Pembeli telah membayar",    "请核实资金到账后再确认打币，切勿未收到转账直接打币造成损失。": "Harap verifikasi bahwa dana akan dikonfirmasi setelah menerima akun.",    "暂停打币成功": "Koin logam berhasil",    "打币成功": "Koin yang sukses",
    // UploadVoucher
    "我已转账": "Saya telah pindah",    "上传成功": "Berhasil mengunggah",
    // AddPayment
    "添加收付款方式": "Tambahkan metode pembayaran",    "姓名": "Nama",    "请输入真实姓名": "Harap masukkan nama asli",    "支付宝账号": "Akun Alipay",    "请输入支付宝账号": "Harap masukkan akun alipay",    "我的收款码": "Kode tanda terima saya",    "支付宝二维码": "Kode QR Alipay",    "上传二维码": "Unggah kode QR",    "上传收款码": "Unggah kode koleksi",    "上传我的收款码": "Unggah kode koleksi saya",    "请输入银行卡卡号": "Harap masukkan nomor kartu bank",    "选择银行": "Pilih bank",    "请选择银行": "Pilih bank",    "添加": "Tambahkan ke",    "上传失败": "Unggah gagal",    "添加成功": "Berhasil",
    // BankList
    "请输入搜索的银行": "Harap masukkan bank pencarian",
    // Payment
    "加载中": "memuat",    "添加收款信息": "Tambahkan informasi tanda terima",    "支付宝名": "Nama Alipay",    "查看二维码": "Lihat kode QR",    "禁用": "Cacat",    "系统禁用": "Sistem nonaktifkan",    "微信名": "Nama WeChat",    "银行名": "Nama bank",    "二维码": "Kode QR",
    // Announcement
    "站内信息": "Informasi di Situs",    "公告内容": "Konten Pengumuman",
    // BannerDetail
    "Banner详情": "Detail spanduk",
    // BuyCoin
    "购买": "Membeli",    "输入购买数量": "Masukkan Jumlah Pembelian",    "可购总数": "Total pembelian",    "最小购买数量": "Jumlah Pembelian Minimum",    "卖家": "Penjual",    "出售数量": "Kuantitas",    "可拆售": "Bongkar",    "不可拆售": "Penjualan yang tidak dibongkar",    "支付": "Membayar",    "请输入购买数量": "Harap masukkan jumlah pembelian",    "购买数量不能小于最小数量": "Kuantitas pembelian tidak bisa kurang dari jumlah minimum",    "请按照订单金额支付": "Harap bayar sesuai dengan jumlah pesanan",
    // fromThirdPay
    "充值金额": "Jumlah pengisian ulang",    "订单时效": "Waktu pesanan",    "pay余额": "Saldo Bayar",    "确认支付": "Konfirmasi pembayaran",    "获得更多": "Dapatkan lebih banyak",    "去充值": "Isi ulang",    "您的余额不足，请充值": "Saldo Anda tidak cukup, silakan isi ulang",    "支付成功": "Pembayaran yang berhasil",
    // fromThirdReg
    "初次使用设置": "Pengaturan untuk Penggunaan Awal",    "设置账号": "Tetapkan nomor akun",    "首字母开头-6-20位": "Mulai dari awal huruf-6-20 digit",    "真实姓名": "Nama asli",    "输入真实姓名": "Masukkan nama asli",    "设置登录密码": "Atur kata sandi login",    "设置支付密码": "Setel Kata Sandi Pembayaran",    "6位数字支付密码": "6 -Gigit Kata Sandi Pembayaran",    "请设置登录密码": "Harap atur kata sandi login",    "设置成功": "Berhasil mengatur",
    // Home
    "暂无公告": "Tidak ada pengumuman",    "回到充值页": "Kembali ke halaman Isi Ulang",    "账号被冻结": "Akun dibekukan",    "请先进行实名认证": "Mohon pertama -tama mengotentikasi nama -Name asli",    "实名审核中": "Ulasan asli -Name",    "该功能已禁用": "Fungsi ini telah dinonaktifkan",    '下一个': 'Berikutnya',    '24小时内不再出现提示': 'Tidak ada petunjuk yang muncul dalam 24 jam',
    // Maintenance
    "系统维护": "Pemeliharaan Sistem",    "维护说明": "Pemeliharaan",    "维护开始时间": "Waktu mulai pemeliharaan",    "维护结束时间": "Waktu akhir pemeliharaan",    '维护时间': 'Waktu pemeliharaan',
    // TradingHall
    "请输入起始金额": "Harap masukkan Jumlah Mulai",    "请输入最大金额": "Harap masukkan jumlah maksimum",    "刷新成功": "Menyegarkan",    "交易提示": "Prompt perdagangan",    "当前暂无交易数据": "Tidak ada data transaksi untuk saat ini",    "请添加付款方式": "Harap tambahkan metode pembayaran",    "请添加对应的付款方式": "Harap tambahkan metode pembayaran yang sesuai",
    // App
    "你有新的订单状态": "Anda memiliki status pesanan baru",    "请立即处理": "Harap ditangani segera",    "付款": "Pembayaran",    "选择客服": "Pilih Layanan Pelanggan",    "客服线路1": "Jalur Layanan Pelanggan 1",    "客服线路2": "Jalur Layanan Pelanggan 2",
    // Certification
    "请输入": "Harap masuk",    "身份证号": "Nomor ID",    "输入身份证号": "Harap masukkan nomor ID",    "上传身份证": "Unggah kartu ID",    "人脸面": "Menghadapi",    "国徽面": "Lambang Nasional",    "提交": "kirim",    "上传异常,请重新上传": "Unggah abnormal, silakan kembali",    "请输入身份证号": "Harap masukkan nomor ID",    "请上传身份证正面": "Harap unggah bagian depan kartu ID",    "请上传身份证反面": "Harap unggah kartu ID Anda",    "认证信息上传成功，请耐心等待审核": "Informasi sertifikasi berhasil diunggah, harap tunggu dengan sabar untuk ditinjau",
    // IdentityVerification
    "为了防范身份信息被冒用，保障资金安全，需进行人脸识别，确保是本人操作": "Untuk mencegah penggunaan informasi identitas dan memastikan keamanan dana, diperlukan pengakuan wajah untuk memastikan bahwa itu adalah operasi saya",    "保持光线充足": "Menjaga cahaya yang cukup",    "竖直持握手机，正对镜头": "Memegang telepon secara vertikal, menghadap kamera",    "跟随提示做出动作": "Ikuti prompt untuk membuat tindakan",    "开始识别": "Mulai Pengakuan",    "身份信息核验":"Tes Informasi Identitas"}
